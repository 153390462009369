<template>
    <div class="clientcabin clientcabin-blow">
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p class="headline text-center pb-10 my-4">
                        Connect With Over 10,000+ Trusted Authority Publishers
                        For A Significant Boost In Rankings, Reputation &
                        Traffic Without Lifting A Finger
                    </p>
                    <p>Want Google to send you more organic traffic?</p>
                    <p>
                        If so, then you should know, Google chooses which
                        websites to send traffic to based on two simple things:
                    </p>
                    <ol class="pb-4">
                        <li>Content - what are you about?</li>
                        <li>Links - what makes you the authority?</li>
                    </ol>
                    <p>Links from other websites are like votes.</p>
                    <p>
                        The more votes from more influential sources you get,
                        the more ‘search elections’ you win.
                    </p>
                    <p>
                        And winning in Google means top rankings and more
                        traffic.
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <div class="clientcabin-darkblue">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <p
                            class="text-h4 white--text quote px-10 text-center mb-0"
                        >
                            Every business that’s serious about getting organic
                            traffic from Google needs a link strategy…
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p>
                        It’s been proven time and time again, study after study,
                        with millions of dollars being invested…
                    </p>
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/blow/referring-domains.png"
                    />
                    <p class="text-center text-h6 font-weight-bold">
                        More Links From More Websites = Higher Rankings & More
                        Traffic
                    </p>
                    <p>
                        You simply cannot hope to get serious traffic from
                        Google without links.
                    </p>
                    <p>So let’s get real…</p>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <p class="subheadline text-center my-4">
                            First - The Bad News…
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p>
                        Getting links from other websites is typically slow,
                        difficult and expensive work - they’re hard to get,
                        which is why they’re so powerful.
                    </p>
                    <p class="font-weight-bold">
                        Here’s the standard cost of an effective link outreach
                        system:
                    </p>
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/blow/yearly-cost.png"
                    />
                    <p class="text-center text-h6 font-weight-bold">
                        Manual Link Outreach Is Extremely Expensive And Slow…
                    </p>
                    <p>
                        Even the experts agree - it is a lot of work with a tiny
                        success rate!
                    </p>
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/blow/expected-results.png"
                    />
                    <p class="text-center text-h6 font-weight-bold">
                        Even The Top Professionals Only See A Success Rate Of
                        0.72%
                    </p>
                    <p>This doesn’t mean you shouldn’t do it, because…</p>
                </v-col>
            </v-row>
        </v-container>
        <div class="clientcabin-darkblue">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <p
                            class="text-h4 white--text quote px-10 text-center mb-0"
                        >
                            Link Outreach is one of the highest ROI investments
                            you can make for your business online…
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/blow/roi.png"
                    />
                    <p class="text-center text-h6 font-weight-bold">
                        Study After Study Has Shown How More Links = More Money
                    </p>
                    <p>
                        As you gain more links from trusted websites and Google
                        ranks you in your target search results higher - you get
                        more and more traffic.
                    </p>
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/blow/google-graph.png"
                    />
                    <p class="text-center text-h6 font-weight-bold">
                        How Many More Sales Would You Make If You Suddenly
                        Doubled Or 10X’d The Number Of Clicks Google Was Sending
                        You?
                    </p>
                    <p>
                        If your competitors aren’t doing this then this is the
                        most obvious way for you to pull ahead of them…
                    </p>
                    <p>
                        If your competitors ARE doing this then you don’t have a
                        choice… you either get going now or fade into obscurity
                        online.
                    </p>
                    <p>Fortunately, you don’t have to do this by yourself…</p>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <p class="subheadline text-center my-4">
                            Now The Good News!
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="white">
            <v-container>
                <v-row justify="center">
                    <v-col
                        cols="12"
                        lg="9"
                        xl="7"
                        class="py-6 py-md-10 text-subtitle-1"
                    >
                        <p>
                            Right here on this page, you can enroll in our
                            done-for-you Blog Link Outreach service - where, you
                            guessed it… we do the work for you.
                        </p>
                        <p>
                            We have connections with over 10,000+ independent
                            publishers who all reach a minimum of 1,000 people
                            every month.
                        </p>
                        <p class="text-center">
                            <b>PRO STAT:</b>
                            Their Domain Authority Is DA 40+ MINIMUM.
                        </p>
                        <p>
                            We can get content published about any site and have
                            them link back to you as a trusted resources -
                            places you can be proud to be seen:
                        </p>
                        <v-img
                            class="mb-4"
                            src="@/assets/img/clientcabin/blow/dreams-of-a-life.png"
                        />
                        <p class="text-center text-h6 font-weight-bold">
                            Boost Your Rankings & Traffic With Our Blog Link
                            Outreach Service To Access 10,000+ Trusted
                            Authoritative DA40+ Sites
                        </p>
                        <p>
                            With our Blog Outreach Service you get ALL the power
                            of a professional outreach team, the links, the
                            rankings and the profits…
                        </p>
                        <p>
                            … without the time, cost, stress or extensive
                            studying, setting up and managing an outreach
                            program of your own.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="clientcabin-darkblue">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <p
                            class="text-h4 white--text quote px-10 text-center mb-0"
                        >
                            You get to focus on what you do best as we grow your
                            business silently in the background.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="pt-6 pt-md-10 text-subtitle-1"
                >
                    <p class="text-center">
                        We have years of experience and thousands of contacts
                        who are going to be eager to publish your content and
                        link back to you, right now.
                    </p>
                    <p class="subheadline text-center py-8">
                        Great! How Does It Work?
                    </p>
                    <p>Simple!</p>
                    <p>
                        Once you select your package below (based on how many
                        links you may want each month… if you’re not sure, just
                        reach out to us!)
                    </p>
                    <p>
                        Just give us the URL of the page you’d like to link to
                        and the text you’d like the link to say.
                    </p>
                    <p>
                        We’ll begin our outreach and within 30 days, the content
                        will be published and your links will be live.
                    </p>
                    <p>
                        We’ll repeat the process for as long as you wish to
                        continue, to help you gain progressively more rankings
                        and traffic over time.
                    </p>
                    <p>
                        <b>Note:</b>
                        <i>
                            The more consistently you acquire links, the more
                            significant the results will be. We’ve created
                            several packages to fit your scale and budget; with
                            heavier discounts for bigger campaigns.
                        </i>
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="mt-n4 pb-6 pb-md-10">
                    <p class="text-center text-h5">Select Your Option Below</p>
                    <v-container class="clientcabin-pricing">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-card elevation="10" class="rounded-lg">
                                    <v-img
                                        class="black--text align-center grey lighten-4"
                                        min-height="160px"
                                    >
                                        <div
                                            class="text-center option-title pa-4 mt-4"
                                        >
                                            Option 1
                                            <span class="option-plan">
                                                Plan 1
                                            </span>
                                            <br />
                                            <b>Single Purchase @</b>
                                        </div>
                                    </v-img>
                                    <div class="price text-center">
                                        {{ reseller_client_blow_pack_price }}
                                    </div>
                                    <v-card-text class="text-center">
                                        <v-btn
                                            x-large
                                            color="primary white--text"
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            class="px-10 py-8 text-h6 rounded-lg"
                                            @click="onOrder('single')"
                                        >
                                            Add to cart
                                        </v-btn>
                                    </v-card-text>
                                    <v-card-text class="pt-0 text-center">
                                        <v-icon class="mr-1">
                                            $vuetify.icons.secure
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.mastercard
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.amex
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.visa
                                        </v-icon>
                                        <v-icon large>
                                            $vuetify.icons.paypal
                                        </v-icon>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card elevation="10" class="rounded-lg">
                                    <v-img
                                        class="black--text align-center grey lighten-4"
                                        min-height="160px"
                                    >
                                        <div
                                            class="text-center mt-n5 primary white--text text-button"
                                        >
                                            Most Popular
                                        </div>
                                        <div
                                            class="text-center option-title pa-4"
                                        >
                                            Option 2
                                            <span class="option-plan">
                                                Plan 2
                                            </span>
                                            <br />
                                            <b>Monthly Purchase @</b>
                                        </div>
                                    </v-img>
                                    <div class="price text-center">
                                        {{
                                            reseller_client_recurring_blow_pack_price
                                        }}
                                    </div>
                                    <v-card-text class="text-center">
                                        <v-btn
                                            x-large
                                            color="primary white--text"
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            class="px-10 py-8 text-h6 rounded-lg"
                                            @click="onOrder('recurring')"
                                        >
                                            Add to cart
                                        </v-btn>
                                    </v-card-text>
                                    <v-card-text class="pt-0 text-center">
                                        <v-icon class="mr-1">
                                            $vuetify.icons.secure
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.mastercard
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.amex
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.visa
                                        </v-icon>
                                        <v-icon large>
                                            $vuetify.icons.paypal
                                        </v-icon>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class BLOW extends Vue {
    endpoint = '/blow';

    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get reseller_client_blow_pack_price() {
        return this.format(this.options?.reseller_client_blow_pack_price);
    }

    get reseller_client_recurring_blow_pack_price() {
        return `${this.format(
            this.options?.reseller_client_recurring_blow_pack_price
        )}/mo`;
    }

    format(price?: number) {
        if (price) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.options?.currency || 'USD',
                maximumFractionDigits: 0
            }).format(price);
        }

        return price;
    }

    onOrder(product: 'single' | 'recurring') {
        this.$http
            .post(this.endpoint, {
                [product]: 1
            })
            .then(({ data }) => {
                if (data.data.success) {
                    this.onSuccess(data.data);
                }
            });
    }

    onSuccess({ redirect }: { redirect?: string }) {
        if (redirect) {
            // payment page
            window.location.href = redirect;
        }
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-blow::v-deep {
    color: $black;
    background-color: $white;

    .option-title {
        font-size: 1.25rem;
        line-height: 1.8em;
        text-transform: uppercase;

        .option-plan {
            border: 2px solid grey;
            padding: 4px 8px;
            margin-left: 8px;
            font-weight: 600;
        }
    }

    .quote {
        font-style: italic;

        &:before {
            content: '\201C';
            font-size: 175%;
            line-height: 0;
            position: relative;
            top: 0.85rem;
            font-family: sans-serif;
        }

        &:after {
            content: '\201D';
            font-size: 175%;
            line-height: 0;
            position: relative;
            top: 0.85rem;
            font-family: sans-serif;
        }
    }

    .price {
        font-weight: 700;
        font-size: 67px;
        letter-spacing: -1px;
        text-align: center;
        padding: 25px 0;
        color: $black;
    }
}
</style>
